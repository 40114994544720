.container {
  width: 50%;
  margin-top: 7px;
  margin-left: 24px;

  .metricsOption {
    display: flex;
    align-items: center;
  }
}
