.zone_growth_option {
  display: inline-flex;
  align-items: center;

  > div {
    margin: 4px 8px;
  }
}

.optionIcon {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left;
  height: 40px;
  width: 40px;

  &.WZS-BZS {
    background-image: url("../../../../assets/images/WZS-BZS.png");
  }
  &.WZC-BZS {
    background-image: url("../../../../assets/images/WZC-BZS.png");
  }
  &.WZC-BZC {
    background-image: url("../../../../assets/images/WZC-BZC.png");
  }
  &.WZD-BZS {
    background-image: url("../../../../assets/images/WZD-BZS.png");
  }
  &.WZD-BZC {
    background-image: url("../../../../assets/images/WZD-BZC.png");
  }
  &.WZD-BZD {
    background-image: url("../../../../assets/images/WZD-BZD.png");
  }

  &.ZCC-ZBC {
    background-image: url("../../../../assets/images/ZCC-ZBC.png");
  }
  &.ZCD-ZBC {
    background-image: url("../../../../assets/images/ZCD-ZBC.png");
  }
  &.ZCC-ZBS {
    background-image: url("../../../../assets/images/ZCC-ZBS.png");
  }
  &.ZCD-ZBD {
    background-image: url("../../../../assets/images/ZCD-ZBD.png");
  }
  &.ZCD-ZBS {
    background-image: url("../../../../assets/images/ZCD-ZBS.png");
  }
  &.ZCS-ZBS {
    background-image: url("../../../../assets/images/ZCS-ZBS.png");
  }
  &.PZS-BZS {
    background-image: url("../../../../assets/images/PZS-BZS.png");
  }
  &.PZC-BZS {
    background-image: url("../../../../assets/images/PZC-BZS.png");
  }
  &.PZC-BZC {
    background-image: url("../../../../assets/images/PZC-BZC.png");
  }
  &.PZD-BZS {
    background-image: url("../../../../assets/images/PZD-BZS.png");
  }
  &.PZD-BZC {
    background-image: url("../../../../assets/images/PZD-BZC.png");
  }
  &.PZD-BZD {
    background-image: url("../../../../assets/images/PZD-BZD.png");
  }
  &.LET_GO {
    background-image: url("../../../../assets/images/let_go.png");
  }
  &.LET_GROW {
    background-image: url("../../../../assets/images/let_grow.png");
  }
  &.WITH_CAUTION {
    background-image: url("../../../../assets/images/with_caution.png");
  }
}
