.card {
  width: 90%;
  margin: 5%;
  height: 90%;
  background: var(--cardBackgroundColor);
  border-radius: var(--borderRadius);
  font-size: 2rem;

  .content {
    overflow: hidden;
    pointer-events: none;

    .body {
      overflow: auto;
      padding: 20px;
    }
  }
}