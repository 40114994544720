.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2vw;
}

.header {
  color: var(--primaryColor);
  font-size: 1.2rem;

  .leaderboard {
    padding: 2vw;
  }
}