.container {
  background-color: var(--gameFooterBackgroundColor);
  width: 100vw;
  height: 10%;
}

.items {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "prev more next";
}

.button {
  background-color: var(--ghostButtonColor);
  color: var(--primaryTextColor);
  font-size: 0.7rem;
  margin: 0;
  height: 100%;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    background-color: var(--ghostButtonDisabledColor);
    color: var(--ghostButtonDisabledTextColor);
  }

  &:hover,
  &:focus {
    background-color: var(--ghostButtonPressedColor);
    color: var(--ghostButtonPressedTextColor);
  }

  &.next {
    grid-area: next;
  }

  &.more {
    grid-area: more;
  }

  &.prev {
    grid-area: prev;
  }
}
