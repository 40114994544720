.layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content:space-between;
  overflow: hidden;

  background-image: url('../../../assets/images/bg-public.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;

  &.noBackground {
    background-image: unset;
  }

  .logo {
    -webkit-background-size: 50%;
    -moz-background-size: 50%;
    background-size: 50%;
    background-image: url('../../../assets/images/logo.png');
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-color: white;
    height: 20vh;
    width: 20vh;
    margin: 5vh auto;
    box-shadow:
      0 0 5px 13vw rgba(246, 246, 246, 0.3),
      0 0 10px 23vw rgba(246, 246, 246, 0.2),
  }

  .pageContent {
    overflow: hidden;
    height: 100vh;
  }
}