
.previewImage {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: -webkit-fill-available;
}

.header {
  color: #fff;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.close {
  cursor: pointer;
  margin: 6px;
}

.download {
  cursor: pointer;
  margin: 6px;

  &.disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}