body {
  background-color: var(--gameBackgroundColor);
}

.container {
  // TODO find a better way
  // white-space: nowrap;
  display: grid;
  grid-template-areas:
    "header"
    "leaderboard"
    "levels"
    "static-pages";
  gap: 3vh;
  padding: 3vh;
}

.header {
  grid-area: header;
  color: var(--primaryColor);
}

.leaderboard {
  font-size: 32px;
  grid-area: leaderboard;
  color: var(--primaryColor);
  border-radius: var(--borderRadius);
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  padding: 20px 0;
  cursor: pointer;
}

.levels {
  grid-area: levels;
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
  grid-gap: 3%;
  font-weight: bold;

  .level {
    position: relative;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: var(--borderRadius);
    color: var(--primaryColor);
    text-align: center;
    padding: 5%;
    cursor: pointer;

    .text {
      color: var(--primaryTextColor);
    }

    .logo {
      width: 100%;
      text-align: center;
    }

    &.disabled {
      color: var(--primaryTextDisabledColor);
      pointer-events: none;

      .text {
        color: var(--primaryTextDisabledColor);
      }
    }

    .lock {
      color: var(--primaryTextColor);
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
}

.staticPages {
  grid-area: static-pages;
  color: var(--primaryTextColor);
  cursor: pointer;

  .page {
    margin-top: 1vh;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: var(--borderRadius);
    text-align: center;
    padding: 3vh 0;
  }
}
