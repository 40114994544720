

.container {
  height: 100%;
  flex: 0 1 auto;
  overflow-y: auto;
}

.header {
  color: var(--primaryTextColor);
  font-size: 20px;
  font-weight: 700;
  height: 10%;

  @media screen and (min-width: 481px) and (max-width: 768px) {
    height: auto;
  }


  &.home {
    color: var(--primaryColor);
  }
}
