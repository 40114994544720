.pageContent {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.backButton {
  width: max-content;
  border: 0;
  border-radius: 0 15px 15px 0;
  padding: 3%;
  color: #217334;
}
