.container {
  width: 100%;

  .label {
    color: var(--secondaryTextColor);
    font-weight: 700;
    font-size: 1.5rem;
    white-space: nowrap;
  }

  .formContainer {
    max-width: 500px;
    height: 100%;
    margin-right: auto;
    margin-left: auto;

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
  
      .submit {
        width: 90%;
        margin-top: 30%;
      }
  
      .forgot {
        background-color: var(--ghostButtonColor);
        color: var(--ghostButtonTextColor);

        &:disabled {
          background-color: var(--ghostButtonDisabledColor);
          color: var(--ghostButtonDisabledTextColor);
        }
      
        &:hover,
        &:focus {
          background-color: var(--ghostButtonPressedColor);
          color: var(--ghostButtonPressedTextColor);
        }
      }
    }
  }
}
