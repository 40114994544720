.container {
  color: var(--primaryTextColor);
  background-color: transparent;
  border-bottom: #C4C4C4 solid 1px;
  padding: 0 5px;
  display: inline-flex;
  width: 100%;
  height: 12vh;
  overflow: hidden;
  text-align: left;
  font-weight: normal;

  .avatar {
    padding: 1vh 0;
  }

  &.unread {
    font-weight: bold;

    .content {
      .msg {
        opacity: 1;
      }
    }

    .date {
      opacity: 1;
    }
  }

  .content {
    width: 60%;
    padding: 10px 3%;
    font-weight: 600;
    cursor: pointer;
    overflow: hidden;
    margin-bottom: auto;
    margin-top: auto;

    * {
      white-space: nowrap;
      text-overflow: clip;
    }

    .msg {
      font-size: 0.8rem;
      opacity: 0.3;
    }
  }

  .date {
    width: 20%;
    font-size: 0.7rem;
    opacity: 0.3;
    margin-bottom: auto;
    margin-top: auto;
  }

  .badge {
    background: #8f9396;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    font-weight: 700;
    font-size: 14px;
  }
}
