.container {
  max-height: 100%;
  overflow-y: auto;
  flex: 1 1 auto;
}

.header {
  padding: 16px;

  .hazardWarn,
  .invasiveWarn {
    text-align: center;
    width: fit-content;
    padding: 1% 3%;
    margin: auto;
    background-color: var(--sectionBackgroundColor);
    border-radius: 50px;

    > span {
      line-height: 100%;
      font-size: 0.7rem;
      padding: 4px 8px;
    }
  }
}

.namesList {
  display: flex;
  align-items: self-start;
  justify-content: space-between;
  margin: 0 auto;
  width: 90%;
  font-size: 0.8rem;
  flex-wrap: wrap;

  > div {
    text-align: left;
    word-break: break-all;
    margin: 2vw;

    > div {
      font-style: italic;
      font-weight: 700;
    }
  }
}

.info {
  display: flex;
  background: var(--sectionBackgroundColor);
  flex-wrap: wrap;

  .details {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-basis: 20%;
    padding: 5px 0;
  }

  .infoLabel {
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    &.slow_growth {
      background-image: url("../../assets/images/slow_growth.png");
    }
    &.medium_growth {
      background-image: url("../../assets/images/medium_growth.png");
    }
    &.fast_growth {
      background-image: url("../../assets/images/fast_growth.png");
    }
    &.max_height {
      background-image: url("../../assets/images/height.png");
    }
    &.max_spread {
      background-image: url("../../assets/images/spread.png");
    }
    &.WZS-BZS {
      background-image: url("../../assets/images/WZS-BZS.png");
    }
    &.WZC-BZS {
      background-image: url("../../assets/images/WZC-BZS.png");
    }
    &.WZC-BZC {
      background-image: url("../../assets/images/WZC-BZC.png");
    }
    &.WZD-BZS {
      background-image: url("../../assets/images/WZD-BZS.png");
    }
    &.WZD-BZC {
      background-image: url("../../assets/images/WZD-BZC.png");
    }
    &.WZD-BZD {
      background-image: url("../../assets/images/WZD-BZD.png");
    }

    &.ZCC-ZBC {
      background-image: url("../../assets/images/ZCC-ZBC.png");
    }
    &.ZCD-ZBC {
      background-image: url("../../assets/images/ZCD-ZBC.png");
    }
    &.ZCC-ZBS {
      background-image: url("../../assets/images/ZCC-ZBS.png");
    }
    &.ZCD-ZBD {
      background-image: url("../../assets/images/ZCD-ZBD.png");
    }
    &.ZCD-ZBS {
      background-image: url("../../assets/images/ZCD-ZBS.png");
    }
    &.ZCS-ZBS {
      background-image: url("../../assets/images/ZCS-ZBS.png");
    }

    &.PZS-BZS {
      background-image: url("../../assets/images/PZS-BZS.png");
    }
    &.PZC-BZS {
      background-image: url("../../assets/images/PZC-BZS.png");
    }
    &.PZC-BZC {
      background-image: url("../../assets/images/PZC-BZC.png");
    }
    &.PZD-BZS {
      background-image: url("../../assets/images/PZD-BZS.png");
    }
    &.PZD-BZC {
      background-image: url("../../assets/images/PZD-BZC.png");
    }
    &.PZD-BZD {
      background-image: url("../../assets/images/PZD-BZD.png");
    }
    &.LET_GO {
      background-image: url("../../assets/images/let_go.png");
    }
    &.LET_GROW {
      background-image: url("../../assets/images/let_grow.png");
    }
    &.WITH_CAUTION {
      background-image: url("../../assets/images/with_caution.png");
    }

    &.culturally_important {
      background-image: url("../../assets/images/culturally_important_plant_icon.png");
    }
    &.mammalFood {
      background-image: url("../../assets/images/mammal_food.png");
    }
    &.birdFood {
      background-image: url("../../assets/images/bird_food.png");
    }
    &.host {
      background-image: url("../../assets/images/host.png");
    }
    &.vines {
      background-image: url("../../assets/images/vines.png");
    }
    &.hazard {
      background-image: url("../../assets/images/hazard.png");
    }
    &.herbaceous {
      background-image: url("../../assets/images/herbaceous.png");
    }
    &.woody_shrubs {
      background-image: url("../../assets/images/woody_shrubs.png");
    }
  }
}

.textInfo {
  font-size: 0.6rem;
  margin-top: 25px;

  div {
    white-space: nowrap;
    line-height: 0.5rem;
  }
}

:global(.carouselList) {
  // justify-content: center;
}

:global(.carouselItem) {
  padding: 16px;
  margin: auto;
}

.noResult {
  font-style: italic;
  font-weight: 700;
}

.sectionContainer {
  width: 100%;
  margin: 24px 0;

  .description {
    flex-grow: 2;
    margin: 0 16px;

    > * {
      font-weight: 400;
    }

    .title {
      font-size: 1.2rem;
      text-transform: capitalize;
      font-weight: 700;
      margin: 5% 0;
    }

    a {
      color: white;
      font-weight: 700;
    }
  }
}

.sectionImageContainer {
  border-radius: var(--borderRadiusThick);
  padding: 2vw;

  &.treatment {
    border-radius: 0;
  }

  img {
    height: unset;
    max-height: 40vh;
  }
}
