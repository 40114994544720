
:root {
    // Main
    --borderRadius: 10px;
    --borderRadiusThick: 26px;
    --primaryColor: #3C9D64;
    --dangerColor: #D6346E;
    --primaryBackgroundColor: #E5E5E5;
    --secondaryBackgroundColor: #DCDCDC;
    --sectionBackgroundColor: #E0E0E0;

    // Texts
    --primaryTextColor: #012F14;
    --secondaryTextColor: #F6F6F6;
    --primaryTextDisabledColor: #D6DBDC;

    // Hamburger menu
    --hamburgerMenuOverlayColor: rgba(60, 157, 100, 0.4);
    --hamburgMenuBackgroundColor: #F6F6F6;
    --hamburgerMenuPrimaryTextColor: #3C9D64;
    --hamburgerMenuDisabledTextColor: #E0E0E0;

    // Card
    --cardBackgroundColor: #FAFAFA;
    --cardSecondaryBackgroundColor: #EFEFEF;

    // Default Button
    --defaultButtonColor: #3C9D64;
    --defaultButtonPressedColor: #47B072;
    --defaultButtonDisabledColor: rgba(137, 137, 137, 0.28);

    --defaultButtonTextColor: #fff;
    --defaultButtonPressedTextColor: #fff;
    --defaultButtonDisabledTextColor: rgba(137, 137, 137, 0.28);

    // Ghost Button
    --ghostButtonColor: transparent;
    --ghostButtonDisabledColor: transparent;
    --ghostButtonPressedColor: rgba(0, 0, 0, 0.06);

    --ghostButtonTextColor: #fff;
    --ghostButtonPressedTextColor: #fff;
    --ghostButtonDisabledTextColor: rgba(0, 0, 0, 1);

    // Secondary Button
    --secondaryButtonColor: #F6F6F6;
    --secondaryButtonPressedColor: #3C9D64;
    --secondaryButtonDisabledColor: rgba(246, 246, 246, 0.6);;

    --secondaryButtonTextColor: #3C9D64;
    --secondaryButtonPressedTextColor: #F6F6F6;
    --secondaryButtonDisabledTextColor: rgba(60, 157, 100, 0.6);

    // Gaming
    --gameBackgroundColor: #D1EACF;
    --progressBarBackgroundColor: #F6F6F6;

    --gameButtonColor: #ffffff;
    --gameButtonTextColor: #000000;

    --gameIconShadowColor: rgba(31, 85, 88, 0.1);
    --gameFooterBackgroundColor: #F3F3F3;

    // Inputs
    --inputBackgroundColor: #F6F6F6;
    --inputDisabledBackgroundColor: rgba(246, 246, 246, 0.4);

    --inputTextColor: rgb(0,0,0);
    --inputDisabledTextColor: rgb(0,0,0, 0.2);
}