.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--hamburgerMenuOverlayColor);
  display: flex;
  align-items:flex-start;
  z-index: 100000;
}

.sideBarMenu {
  width: 100%;
  background: var(--hamburgMenuBackgroundColor);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .header {
    width: 96%;
    padding: 1vh 2%;
    height: 10vh;
    font-weight: bold;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;

    .closeIcon {
      color: var(--hamburgerMenuPrimaryTextColor);
      cursor: pointer;
      font-size: 1.5rem;
    }
  }

  .content {
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    padding: 1vh 2%;
    margin-top: 8vh;

    .item {
      font-weight: 600;
      width: 100%;
      text-align: right;
      margin: 2%;
      cursor: pointer;

      .icon {
        color: var(--hamburgerMenuPrimaryTextColor);
        padding: 10px;
        margin-left: 5%;
        margin-right: 10px;
        font-size: 1.5rem;
      }

      &.disabled {
        color: var(--hamburgerMenuDisabledTextColor);
        pointer-events: none;
      }
    }
  }

  @media screen and (min-width: 700px) {
    justify-content: flex-start;
    font-size: 1.2rem;
    max-width: 50%;
    height: 100%;

    .content {
      margin-top: 0vh;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}