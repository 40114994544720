.container {
  background-color: var(--primaryBackgroundColor);
  top: 0;
  height: 100%;
  width: 100%;
  padding: 5% 15%;
  position: absolute;
  overflow-y: scroll;

  .header {
    width: 98%;
    padding: 1%;
  
    .text {
      margin-left: auto;
      margin-right: auto;
      font-weight: bold;
      text-align: center;
    }
  }

  .content {
    padding: 5% 0;
    overflow-y: auto;
  }
}

.plants {
  overflow-y: unset;
}

.close {
  position: absolute;
  padding: 5%;
  right: 1%;
  top: 0;
}
