
.password {
  position: relative;

  input {
    width: 90%;
    padding: 2vh;
    margin: 2vh;
  }

  .toggler {
    margin-right: 5%;
    position: absolute;
    right: 0;
    outline: none;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }
  }
}
