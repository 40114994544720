.footer {
  height: 50px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  .logo {
    height: 80%;
  }
}
