  .option {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    padding: 5%;
    cursor: pointer;
    background: var(--cardSecondaryBackgroundColor);
    margin: 2% auto 0% auto;
    box-shadow: 0px 24px 14px rgba(31, 85, 88, 0.1);

    @media screen and (min-width: 481px) and (max-width: 768px) {
      width: 48%;
      display: inline-block;
      margin: 0 0 20px 0;
    }

    .header {
      padding-top: 8px;
      text-align: left;
      font-weight: 700;

      &.alignCenter {
        text-align: center;
      }
    }
    
    .description {
      padding-top: 8px;
      word-break: break-word;
      text-align: justify;
    }
  }
