.container {
  justify-content: stretch;
  width: 100%;
  display: grid;
  grid-template-areas:
    "header header"
    "title levels"
    "stats stats";
  grid-template-rows: auto auto 1fr;
  gap: 3vh;
  padding: 2vh;
  height: 100%;
  overflow-y: auto;
}

.container div:not(:nth-child(2)) {
  white-space: nowrap;
}

.header {
  grid-area: header;
  display: flex;
  justify-content: start;

  &.closeButton {
    justify-content: end;
  }

  .game {
    background-color: var(--primaryColor);
    color: var(--secondaryTextColor);
    border-radius: var(--borderRadius);
    padding: 5px;
    font-weight: bold;

    > span {
      margin-left: 2.5px;
    }
  }
}

.title {
  text-align: left;
  grid-area: title;
  color: var(--primaryColor);
  z-index: 999;

  h4 > span {
    margin-left: 10px;
  }
}

.levels {
  grid-area: levels;
  z-index: 999;
}

.stats {
  grid-area: stats;
  overflow-y: auto;
  padding: 10px;
  color: var(--primaryColor);
  border-radius: var(--borderRadius);
  background-color: rgba(255, 255, 255, 0.8);
  text-align: center;
  z-index: 99;
}
