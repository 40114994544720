.container {
    position: absolute;
    top: 40%;
    height: 60%;
    padding: 2%;
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: var(--borderRadius);

    .search {
        height: 15%;

        input {
            width: 100%;

            &::placeholder {
                color: #000;
                opacity: 0.5;
            }
        }
    }

    .empty {
        opacity: 0.3;
        width: 100%;
        text-align: center;
        margin-top: 30%;
    }

    .recipients {
        padding-top: 2%;
        height: auto;
        overflow-y: auto;
        overflow-x: hidden;

        .recipient {
            padding: 10px;
            display: flex;

            .details {
                margin-left: 4%;
                width: -webkit-fill-available;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .send {
                    background-color: transparent;
                    color: var(--secondaryButtonTextColor);
                    padding: 10px;

                    &:focus,
                    &:hover {
                        color: var(--secondaryButtonPressedTextColor);
                        background-color: var(--secondaryButtonPressedColor);
                    }
                }
            }
        }
    }
}

.avatar {
    height: 7vh;
    width: 7vh;
    font-size: 24px;
}