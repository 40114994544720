.container {
    overflow-y: auto;
    height: 100%;
    flex: 1 1 auto;
}

.locked {
    flex: 1 1 auto;
    text-align: center;
    justify-content: center;
    padding-top: 30%;
}