.container {
  height: -webkit-fill-available;

  .summary {
    margin-top: 4vw;

    .imageContainer {
      border-radius: 50%;
      background-color: white;
      height: 20vh;
      width: 20vh;
      margin: auto;
      box-shadow: 0 0 5px 4vw var(--gameIconShadowColor);
      color: var(--primaryColor);

      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;

      div {
        height: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      > img {
        border-radius: inherit;
        width: 100%;
        height: 100%;
      }
    }

    .customImage {
      border-radius: 10px;
      width: 100%;

      img {
        border-radius: inherit;
        max-width: 100%;
        max-height: 30vh;
        margin: auto;
      }
    }

    .description {
      &.submit {
        font-weight: normal;
        white-space: nowrap;
      }
    }

    .header {
      font-size: 1.3rem;
      margin-top: 5%;
      &.fail {
        color: var(--dangerColor); 
      }

      &.submit,
      &.success {
        color: var(--primaryColor); 
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 5%;

      .button {
        background-color: var(--gameButtonColor);
        color: var(--gameButtonTextColor);
        width: fit-content;
        box-shadow: 0px 24px 14px rgba(31, 85, 88, 0.1);

        > span {
          margin-left: 10px;
        }
      }
    }
  }
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;

  .button {
    color: var(--primaryTextColor);
    background-color: var(--ghostButtonColor);

    &:disabled {
      background-color: var(--ghostButtonDisabledColor);
      color: var(--ghostButtonDisabledTextColor);
    }
  
    &:hover,
    &:focus {
      background-color: var(--ghostButtonPressedColor);
      color: var(--ghostButtonPressedTextColor);
    }
  }
}