.container {
  background-color: var(--secondaryBackgroundColor);
  padding: 10px;
  width: 100vw;
  height: fit-content;

  .uploadedList {
    background-color: var(--inputBackgroundColor);
    width: fit-content;
    border-radius: 4px 4px 0 0px;
    max-width: 81%;
  }

  .reply {
    display: flex;
    width: 100%;
    height: 100%;

    .upload {
      width: 10%;
      padding: 0;
      margin: 0;
      border-radius: 0px 4px 4px 0px;
      border-left: none;
    }
  
    .replyMessage {
      resize: none;
      padding: 2%;
      border-radius: 4px 0px 0px 4px;
      background: var(--inputBackgroundColor) !important;
      color: var(--inputTextColor) !important;
      width: 75%;
    }
  
    .send {
      cursor: pointer;
      border-radius: 4px;
      width: 15%;
      padding: 0;
      margin: 0;
      margin-left: 5%;
      color: var(--secondaryButtonTextColor);
      background-color: var(--secondaryButtonColor);
  
      &:focus,
      &:hover {
        color: var(--secondaryButtonPressedTextColor);
        background-color: var(--secondaryButtonPressedColor);
      }
  
      &:disabled {
        color: var(--secondaryButtonDisabledTextColor);
        background-color: var(--secondaryButtonDisabledColor);
      }
    }
  }
}
