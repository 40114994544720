.container {
  width: 50%;
  margin-left: 24px;

  .languageOption {
    display: flex;
    align-items: center;
  }

  .flag-en,
  .flag-es {
    height: 30px;
    background-size: cover;
    width: 30px;
    margin-right: 10px;
  }

  .flag-en {
    background-image: url("../../../../../../assets/images/flags/en.png");
  }

  .flag-es {
    background-image: url("../../../../../../assets/images/flags/es.png");
  }
}
