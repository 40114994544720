html,
body,
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: var(--primaryTextColor);
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Montserrat", sans-serif;
  background-color: var(--primaryBackgroundColor);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

img {
  max-width: 100%;
  max-height: 100%;
  display: block; /* remove extra space below image */
}

a {
  color: inherit;

  /* visited link */
  &:visited,
  &:hover,
  &:link,
  &:active {
    color: inherit;
  }
}

input {
  outline: none;
  border-radius: var(--borderRadius);
  border: none;
  background-color: var(--inputBackgroundColor);
  color: var(--inputTextColor);
  border-radius: 10px;

  &::placeholder {
    color: #000;
    opacity: 1;
  }

  &:disabled {
    background-color: var(--inputDisabledBackgroundColor);
    color: var(--inputDisabledTextColor);
  }
}

textarea {
  outline: none;
  border-radius: var(--borderRadius);
  border: none;
  background-color: var(--inputBackgroundColor);
  color: var(--inputTextColor);
  border-radius: 10px;

  &::placeholder {
    color: #000;
    opacity: 1;
  }

  &:disabled {
    background-color: var(--inputDisabledBackgroundColor);
    color: var(--inputDisabledTextColor);
  }
}

button {
  font-weight: 700;
  border-radius: var(--borderRadius);
  background-color: var(--defaultButtonColor);
  color: var(--defaultButtonTextColor);
  border: none;
  padding: 2vh;
  margin: 2vh;

  &:disabled {
    background-color: var(--defaultButtonDisabledColor);
    color: var(--defaultButtonDisabledTextColor);
  }

  &:hover {
    background-color: var(--defaultButtonPressedColor);
    color: var(--defaultButtonPressedTextColor);
  }
}

a {
  outline: none;
  &:focus,
  &:hover {
    text-decoration: none;
  }
}

div {
  letter-spacing: 0em;
}

h1,
h2,
h3,
h4 {
  font-weight: 600;
}

@media (max-height: 600px) {
  button {
    padding: 1.3vh;
    margin: 1.3vh;
  }
}
