.container {
  height: 100%;
  overflow-y: auto;
}

.header {
  padding: 16px;
  font-weight: 600;
  text-align: start;
  text-align: center;

  > div {
    color: red;
    font-size: 0.7rem;
    padding: 4px 8px;
    background-color: white;
  }
}

.image {
  border-radius: var(--borderRadiusThick);
}

.title {
  text-transform: capitalize;
}

.noResult {
  font-style: italic;
  color: wheat;
  font-weight: 700;
}

.content {
  padding: 5%;
  color: black;
  text-align: justify;
  background-color: white;
}
