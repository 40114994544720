.selector {
  font-size: 0.9rem;

  a {
    color: var(--primaryTextDisabledColor);
    padding: 0;
  }

  a:hover,
  a:focus {
    color: var(--primaryColor);
  }

  div {
    font-size: inherit;
    padding: 5px;
  }
}

.singleCustomer {
  color: var(--primaryColor);
}

.error {
  color: var(--primaryTextDisabledColor);
  font-size: 10px;
  white-space: break-spaces;
}
