.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}

.modal {
  width: 96%;
  height: 90%;
  overflow: hidden;
  border-radius: 0.7rem;
  font-size: 2rem;

  .content {
    overflow: hidden;
    pointer-events: none;
    padding: 1vh;

    > .header {
      width: 100%;
      font-size: 1.5rem;
      padding: 1vh;
      font-weight: bold;
      text-transform: uppercase;
      pointer-events: auto;
    }

    .close {
      pointer-events: auto;
      width: 100%;
      display: flex;
      justify-content: flex-end;
      color: white;
      cursor: pointer;

      :hover {
        color: #ffffffd1;
      }
    }

    &:empty {
      padding-top: 0;
    }

    &.show {
      display: block;
    }

    .body {
      overflow: auto;
      max-height: 80vh;
      min-height: 30vh;
      padding: 20px;
    }

    .footer {
      width: 96%;
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
      max-height: 15rem;
      padding: 1vh;

      & > div {
        margin: 2%;
      }
    }
  }
}