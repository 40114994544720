.container {
  margin: 8px;
  border-radius: 4px;

  &.expanded {
    background-color: #e9e7e5;
  }

  textarea {
    resize: none;
  }
}

.newMessage {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 3vh;
  padding: 15px;
  background-color: #fff;
  color: var(--primaryColor);
  border: var(--primaryColor) 2px solid;
  border-radius: 50%;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;

  &.expanded {
    color: darkgray;
    border: none
  }
}