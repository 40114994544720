.upload {
  margin: 5px 0;
  white-space: nowrap;
  font-weight: bold;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--inputBackgroundColor);
  color: var(--inputTextColor);

  .uploadIcon {
    color: var(--primaryColor);
  }

  label {
    width: 100%;
    line-height: 32px;
    cursor: pointer;
    margin-bottom: 0;
  }
  input[type="file"] {
    width: 0;
    opacity: 0;
    padding: 0;
    visibility: hidden;
  }
}

.uploadedList {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  overflow-x: auto;

  .uploadedItem {
    background: var(--defaultButtonColor);
    color: var(--defaultButtonTextColor);
    padding: 4px;
    margin: 8px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: var(--defaultButtonPressedColor);
      color: var(--defaultButtonPressedTextColor);
    }

    .fileName {
      margin: 0 5px;
    }

    .remove {
      &:hover {
        color: rgba(255, 0, 0, 1);
      }
    }
  }
}
