.react-multi-carousel-dot-list {
    margin: 5px;
}

.react-multi-carousel-dot button {
    border: none;
    background: white;
}

.react-multi-carousel-dot--active button {
    background: var(--primaryColor);
}