.table {
  color: var(--primaryTextColor);
  width: 95%;
  margin: 10px;
}


.header > .score {
  border: none;
}

.score {
  text-align: right;
  border-left: 1px solid var(--primaryTextColor);
}

.row {

  td {
    padding: 10px 0;
  }

  &:nth-child(1) > td > svg {
    color: #FF9900;
  }

  &:nth-child(2) > td > svg {
    color: #BEBEBE;
  }

  &:nth-child(3) > td > svg {
    color: #DE8552;
  }

  .name {
    text-align: left;
    white-space: break-spaces;
    
    &.currentAccount {
      color: var(--primaryColor) !important;

      & > svg {
        color: var(--primaryColor) !important;
      }
    }
  
    > span {
      margin-left: 5px;
    }
  }
  
  .score {
    text-align: right;
    border-left: 1px solid var(--primaryTextColor);
  }
}