
.imageWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  .image {
    cursor: pointer;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30%;
    margin: 1%;
  }
}
