.images {
  background-color: transparent;
  padding: 0;
}

.image {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-bottom: 16px;
  max-height: 45vh;
  height: unset;
}

.container {
  padding: 0 2vw;
  height: 90%;
  overflow-y: auto;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 100%;

  .text {
    padding: 2vh 0;
  }
}