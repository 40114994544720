.container {
  width: 100%;

  .label {
    font-weight: 700;
    font-size: 1.5rem;
    white-space: nowrap;
    color: var(--primaryColor);

    > span {
      color: black;
      margin-left: 10px;
    }
  }

  .formContainer {
    max-width: 500px;
    height: 100%;
    margin-right: auto;
    margin-left: auto;

    .pwdHints {
      width: 90%;
      margin: 0% 5%;
      font-size: 0.7rem;
      text-align: left;
      color: var(--primaryColor);
      font-weight: bold;
    }

    .footer {
      margin: auto;
      width: 100%;
      max-width: 500px;
      position: absolute;
      bottom: 0;
    }

    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;

      .submit {
        width: 90%;
      }
    }
  }
}
