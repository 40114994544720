.container {
  background-color: white;
  top: 20%;
  height: 60%;
  width: 95%;
  padding: 2.5%;
  position: relative;
  margin: auto;
  grid-column: 1fr 1fr;
  border-radius: var(--borderRadius);
  border: var(--primaryBackgroundColor) solid 1px;

  .content {
    padding: 2%;
    overflow-y: scroll;
    text-align: justify;
    max-height: 85%;

    .images {
      background-color: white;
    }
  }

  .footer {
    display: flex;
    justify-content: end;
    position: absolute;
    width: 100%;
    background-color: #fff;
    bottom: 0;
    left: 0;
  }
}
