.container {
  width: 100%;

  .label {
    color: var(--secondaryTextColor);
    font-weight: 700;
    font-size: 1.5rem;
    white-space: nowrap;
  }

  .formContainer {
    max-width: 500px;
    height: 100%;
    margin-right: auto;
    margin-left: auto;

    .form {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      position: relative;
  
      .submit {
        position: relative;
        width: 90%;
        margin-top: 60%;
      }
    }
  }
}
