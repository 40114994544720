.container {
  transition: all 0.5s ease-in-out;
  height: 100%;
  display: grid;
  grid-template-rows: 10% 1fr auto;
  overflow: hidden;
}

.empty {
  position: relative;
  top: 50%;
  opacity: 0.4;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.messages {
  overflow-y: auto;
  background-color: var(--secondaryBackgroundColor);
}

.header {
  display: flex;
  padding: 2%;
  align-items: center;
  border-top: #c4c4c4 1px solid;

  .avatar {
    height: 5vh;
    width: 5vh;
    font-size: 24px;
  }

  .name {
    margin-left: 4%;
  }
}
