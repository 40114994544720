.message {
  display: flex;
  justify-content: left;

  .content {
    padding: 5px;
    background: #fff;
    color: var(--primaryTextColor);
    border-radius: 10px;
    word-wrap: break-word;

    .messageText {
      a {
        text-decoration: underline;
        color: blue;
      }
    }
  }

  .timeSent {
    font-size: 9px;
    font-style: italic;
    text-align: right;
    margin-top: 4px;
    margin-left: 2px;
  }

  .forwarded {
    font-size: 0.8rem;
    border-radius: 10px;
    padding: 5px;
    width: 100%;
    text-align: left;

    > a {
      text-decoration: underline;
    }

    > p {
      font-style: italic;
    }
  }

  &.my {
    justify-content: flex-end;

    .content {
      background: var(--primaryColor);
      color: white;
    }
    .timeSent {
      margin-right: 2px;
    }
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    max-width: 75%;
    margin: 8px 16px;
  }

  .translate {
    display: flex;
    justify-content: flex-start;

    .translateToggler {
      font-size: 14px;
      text-decoration: underline;
      margin: 5px;
    }
  }
}

.forward {
  cursor: pointer;
  color: rgb(0, 162, 255);
  text-align: initial;
  text-decoration: underline;
}
