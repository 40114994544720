.container {
  display: flex;
  flex-direction: column;
  height: 100%;

  @media screen and (min-width: 481px) and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px;
    margin: 0 !important;
  }

  .image {
    width: 100%;
    height: 35vh;
    margin: auto;

    @media screen and (min-width: 481px) and (max-width: 768px) {
      height: 20vh;
      margin-bottom: 15px;
    }
  }

  .option {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    min-height: 55%;
    max-height: 55%;
    max-width: 50vh;
    width: 85vw;
    margin: 0;
    box-shadow: 0px 24px 14px rgba(31, 85, 88, 0.1);

    @media screen and (min-width: 481px) and (max-width: 768px) {
      padding: 20px 0;
    }
  }

  .content {
    max-width: 50vh;
    margin: 0 auto;
    text-align: center;
    word-break: break-word;
    flex: 1 1 auto;
    font-size: 30px;

    .header {
      color: var(--primaryColor);
      font-size: 40px;
      font-weight: 700;
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;

      @media screen and (min-width: 481px) and (max-width: 768px) {
         font-size: 34px;
      }
    }

    @media screen and (max-width: 800px) and (max-height: 500px) {
      font-size: 10px;

      .header {
        font-size: 12px;
      }
    }
  }
}
