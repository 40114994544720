  .container {
    height: 100%;
    padding-bottom: 3%;
    display: grid;
    grid-template-areas: 'tabs' 'content';
    grid-template-rows: auto 1fr;
    grid-auto-columns: minmax(0, 1fr);
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    background-color: var(--secondaryBackgroundColor);

    .tabs {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: stretch;
      border-bottom: solid 2px #CECECE;
      background-color: var(--primaryBackgroundColor);

      .tab {
        flex: 1 1 50%;
        font-size: 0.8rem;
        height: 100%;
        padding: 3%;
        font-weight: bold;
        border-bottom: solid 2px var(--primaryColor);

        &.active {
          background-color: #3C9D64;
          color: white;
        }

      }
    }

    .content {
      overflow-y: auto;
      height: 100%;
    }
  }
  
  .container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }
