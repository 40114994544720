.container {
  margin: 8px;
  color: #eee;
  .buttonAdd {
    margin-left: 8px;
    padding: 4px 32px;
    border: none;
    background: white;
    color: #217334;
    font-weight: 600;
  }
}
