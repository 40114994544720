.container {
  display: flex;
  flex-direction: column;
  height: 90%;
  padding-bottom: 3%;
  overflow-y: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */

  @media screen and (min-width: 481px) and (max-width: 768px) {
    padding-right: 20px;
    padding-left: 20px;
    margin-top: 40px;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    height: auto;
  }

  &.home {
    flex-direction: row;
    overflow-x: auto;

    @media screen and (min-width: 481px) and (max-width: 768px) {
      flex-direction: column;
      height: auto;
    }

    & > div {
      margin-left: 3vw;
    }

    & > div:last-child {
      margin-right: 3vw;
      padding-right: 3vw; 
    }
  }
}

.container::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}