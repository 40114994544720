.name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .role {
    padding: 10px;
    font-size: 0.8rem;
    border-radius: 10px;
    margin: 4px;

    &.admin {
      background-color: #EFEFEF;
      color: #000000;
      opacity: 0.3;
    }
  }
}
