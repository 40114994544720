.container {
  width: 100%;
  margin: 2% 0;
  padding: 0 2vw;

  .progress {
    background-color: var(--progressBarBackgroundColor);
    height: 0.6rem;

    .bar {
      background-color: var(--primaryColor);
    }
  }

  .score {
    margin: 2%;
    width: 100%;
    text-align: center;
    margin: auto;
  }
}