.App {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none;
  display: flex;
  flex-direction: column;
}
