  .option {
    font-size: 12px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 15vh;
    padding: 0;
    margin: 2% auto 0% auto;
    box-shadow: 0px 24px 14px rgba(31, 85, 88, 0.1);

    .triangle {
      width: 35%;
      height: 100%;
      clip-path: polygon(70% 0%, 100% 50%, 70% 100%, 0 100%, 0 0);
    }

    .content {
      width: 59%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: left;
      height: 94%;
      padding: 3%;

      .description {
        text-overflow: ellipsis;
        overflow: hidden; 
        width: 100%; 
        height: 100%;
        line-height: 100%;
        word-break: break-word;
      }
  
      .title {
        color: var(--primaryTextColor);
        font-weight: 700;
        text-transform: uppercase;
      }
    }
  }