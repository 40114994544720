.header {
  width: 94%;
  padding: 3%;
  font-size: 1.3rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .home {
    font-size: 0.8rem;
    color: #000;
    cursor: pointer;

    span {
      margin-left: 10px;
    }
  }
}
