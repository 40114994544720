.container {
    position: absolute;
    bottom: 0%;
    padding: 1rem;
    background-color: #fff;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px 4px 0px 0px; 

    .actionBtn {
        width: max-content;
        color: var(--primaryColor);
        background-color: transparent;
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        cursor: pointer;
        padding: 1rem;
        margin: 0.5rem;
        &:hover{
            color: var(--secondaryTextColor);
            background-color: var(--primaryColor);
        }
        span {
            margin-right: 10px;
        }
    }
}