.container {
  height: 100%;
  overflow: auto;

  .list {
    background: var(--sectionBackgroundColor);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;

    .item {
      border-radius: 10px;
      width: 20%;
      overflow: hidden;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @media screen and (min-width: 481px) and (max-width: 768px) {
        width: 33.3%;
        
        .image {
          object-fit: cover;
        }
      }

      @media screen and (max-width: 481px) {
        width: 50%;
      }

      .optionImageContainer {
        border-radius: 10px;
        height: 15vh;
        display: flex;
      }

      .cover {
        height: 15vh;
      }

      .title {
        padding: 6px 3px;
        font-size: 1rem;
        line-height: 1.3rem;
        font-weight: 500;
        overflow: hidden;
        height: 6vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }

  .noResult {
    margin: auto;
    display: flex;
    font-style: italic;
    font-weight: 700;
    padding: 32px;
  }
}

.header {
  color: var(--primaryTextColor);
  font-size: 20px;
  font-weight: 700;
  height: 10%;
}

.image {
  width: 100%;
  height: 100%;
}
