
input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: transparent;

  font: inherit;
  color: #000;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid #000;
  border-radius: 0.15em;
  transform: translateY(-0.075em);

  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
content: "";
width: 0.65em;
height: 0.65em;
clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
transform: scale(0);
transform-origin: bottom left;
transition: 120ms transform ease-in-out;
box-shadow: inset 1em 1em var(--primaryColor);
/* Windows High Contrast Mode */
background-color: CanvasText;
}

input[type="checkbox"]:checked {
color: var(--primaryColor);
border: 0.15em solid var(--primaryColor);
}

input[type="checkbox"]:checked::before {
transform: scale(1);
color: var(--primaryColor);
border: 0.15em solid var(--primaryColor);
}

input[type="checkbox"]:disabled {
--form-control-color: var(--form-control-disabled);

color: var(--form-control-disabled);
cursor: not-allowed;
}