.container {
  text-align: center;
  height: 100%;

  .conversations {
    text-align: center;
    border-top: #C4C4C4 solid 1px;
    height: 100%;
    overflow-y: auto;
  }
}

.emptyList {
  font-size: 20px;
  font-weight: 700;
  padding: 80px 0;
}
