.container {
  display: flex;
  justify-content: space-between;
}

.headerItem {
  position: relative;
  color: var(--primaryColor);
  background-color: var(--secondaryButtonColor);
  border-radius: var(--borderRadius);
  margin: 2%;
  padding: 8px 16px;
  text-align: center;

  &.disabled,
  &:disabled {
    pointer-events: none;
    background-color: var(--secondaryButtonDisabledColor);
    color: var(--secondaryButtonDisabledTextColor);
  }

  &:focus,
  &:hover {
    cursor: pointer;
    background-color: var(--secondaryButtonPressedColor);
    color: var(--secondaryButtonPressedTextColor);
  }

  .badge {
    position: absolute;
    top: -5px;
    right: -10px;
    background: #FF4040;
    color: white;
    width: 22px;
    height: 22px;
    border: 3px solid #EAEAEA;
    border-radius: 50%;
    font-weight: 700;
    font-size: 0.7rem;
  }
}
