.formBg {
  width: 100%;
  background-color: var(--secondaryBackgroundColor);
}

.formLayout {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  height: 100%;
  justify-content: space-between;
}

.invalidSearch {
  color: red;
}

.name {
  padding: 4%;
  background-color: var(--primaryBackgroundColor);
}

.search {
  width: 90%;
}

.checkboxes {
  margin: 0 !important;
}
