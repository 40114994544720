.react-select__control {
  border-radius: var(--borderRadius) !important;
}

.selector {
  font-size: 0.9rem;

  a {
    color: var(--primaryTextDisabledColor);
    padding: 0;
  }

  a:hover,
  a:focus {
    color: var(--primaryColor);
  }

  div {
    font-size: inherit;
  }
}
