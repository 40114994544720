.container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex: 1;

  .answer {
    position: relative;
    display: grid;
    width: -webkit-fill-available;
    max-width: 94%;
    min-width: 46%;
    background-color: white;
    margin: 2%;
    border-radius: var(--borderRadius);
    box-shadow: 0px 24px 14px rgba(31, 85, 88, 0.1);

    .text {
      padding: 5%;
      word-break: break-word;
      word-wrap: break-word;
    }

    .mark {
      position: absolute;
      border-radius: 50%;
      border: 1px solid #B9B9B9;
      background-color: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      width: 28px;
      height: 28px;
      left: -10px;
      top: -10px;
    }

    &.disabled {
      pointer-events: none;
    }

    &.correct {
      border: 4px solid #3C9D64;

      .mark {
        border: 3px solid #3C9D64;
      }
    }

    &.incorrect {
      border: 4px solid #FF0000;

      .mark {
        border: 3px solid #FF0000;
      }
    }
  }
}

.image {
  border-radius: var(--borderRadius);
  box-shadow:
    15vw 0px 0.1em 0px rgba(196, 196, 196, 0.3),
    25vw 0px 0.2em 0px rgba(196, 196, 196,0.3),
    35vw 0px 0.3em 0px rgba(196, 196, 196,0.3);
  width: 50vw;
  max-height: 25vh;
}