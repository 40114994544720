.avatar {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 32px;
  width: 10vh;
  height: 10vh;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
