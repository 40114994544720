.header {
  display: flex;
  align-items: center;

  .avatar {
    margin: 0 15px;
    color: var(--hamburgerMenuPrimaryTextColor);
  }
}

.selector {
  font-size: 0.9rem;

  a {
    color: var(--primaryTextDisabledColor);
    padding: 0;
  }

  a:hover,
  a:focus {
    color: var(--primaryColor);
  }

  div {
    font-size: inherit;
    padding: 5px;
  }
}
