.form-control {
  background: #F6F6F6;
  border-radius: var(--borderRadius);
  border: none;
}

.form-check-input {
  margin-left: -1.8rem;
}

.input-group-text {
  background: #F6F6F6;
  border-radius: var(--borderRadius);
}
